@for $index from 1 through 6 {
  h#{$index} {
    //margin: calc(0.25rem - 1px);
  }
}

p,
li {
  padding: 10px;
}
