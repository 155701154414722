.categories {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  &__card {
    position: relative;
    //border: solid 1px #333;

    padding: 10px;

    &:nth-child(even) {
      background-color: var(--EVEN);
    }
    &:nth-child(odd) {
      background-color: var(--ODD);
    }

    //border-radius: 3px;
    margin-bottom: 5px;

    //box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

    .svg-inline--fa {
      position: absolute;
      right: 5px;

      top: 50%;
      transform: translateY(-50%);
      color: rgba(rgb(10, 113, 148), 1);
    }
  }

  input,
  textarea {
    margin: 0;
    max-width: 100%;
  }
  &__list__items {
    div:first-child {
      padding-left: 10px;
    }
    display: grid;

    grid-template-columns: 4fr 2fr 1fr;

    padding: 10px ;

    &:nth-child(even) {
      background-color: var(--EVEN);
    }
    &:nth-child(odd) {
      background-color: var(--ODD);
    }
  }
}
