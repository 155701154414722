$padding: 10px;

.accordion__wrapper {
  position: relative;
  padding: 0;
  //border: 0.2rem solid #cec3c3f7;
  border-radius: 0;

  margin-right: 0;
  margin-left: 0;
  color: var(--UI-Toggle);

  .accordion {
    color: var(--TEXT-COLOR);

    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;

    .svg-inline--fa {
      color: var(--UI-Toggle);
      margin-right: 10px;
    }

    &-header {
      margin-bottom: 0;
    }

    &-item {
      background-color: var(--ODD);
      border: 1px solid rgba(0, 0, 0, 0.125);
    }

    &-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
    }

    &-button:not(.collapsed) {
      color: var(--TEXT-COLOR);
      background-color: var(--ODD);
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 13%);
    }

    &-button {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 1rem 1.25rem;
      font-size: 1rem;
      color: var(--TEXT-COLOR);
      text-align: left;
      background-color: var(--EVEN);
      border: 0;
      border-radius: 0;
      overflow-anchor: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    }

    &-button:after {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: auto;
      content: "";
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 1.25rem;
      transition: transform 0.2s ease-in-out;
    }
    &-button:not(.collapsed):after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      transform: rotate(-180deg);
    }
  }
}
