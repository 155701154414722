.login {
  * {
    // border: solid 1px red;
  }

  margin: 5%;
  @media (min-width: 600px) {
    margin: 20%;
  }

  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  .logo {
    padding: 5%;

    @media (min-width: 600px) {
      padding: 15%;
    }

    color: var(--BTN-COLOR);
  }
  form {
    h1 {
      text-align: center;
      font-size: 800;
      text-transform: uppercase;
    }
    padding: 10%;

    input {
      width: 100%;
    }

    input:not([type="submit"]) {
      border-radius: 6px;
      padding: 8px;
      margin-top: 5px;
      background-color: var(--INPUT-COLOR);
    }

    .form-element {
      position: relative;

      .svg-inline--fa {
        position: absolute;
        right: 8px;
        top: 50%;
        font-size: 25px;
        transform: translateY(-50%);
        color: var(--BTN-COLOR);
      }
    }
  }
}
