$orange: #fd5739;
$teal: #038387;

:root {
  --body-background-color: #ffffff;
  --link-color: #ff8300;
  --background-color: #3d515c;
  --font-color: #d4dadf;
  --UI-Toggle: #cccccc;
  --UI-Toggle-back: #{$teal};
  --UI-Toggle-label: #ffffff;
  --ODD: #eff7fa;
  --EVEN: #e1e5e5;
  --TEXT-COLOR: #333333;
  --BTN-COLOR: #{$teal};
  --BTN-BORDER: transparent;
  --BTN-HOVER: #026164;
  --BTN-BORDER-HOVER: transparent;
  --PREVIEW: #026164;
  --INPUT-COLOR: #cccccc;
  --fa-icon:#026164;
}

[data-theme="dark"] {
  --body-background-color: #111111;
  --link-color: #0a0908;
  --background-color: #3d515c;
  --font-color: #d4dadf;
  --UI-Toggle: #ffffff;
  --UI-Toggle-back: #{$orange};
  --UI-Toggle-label: #181818;
  --ODD: #353131;
  --EVEN: #181818;
  --TEXT-COLOR: #ffffff;
  --BTN-COLOR: #{$orange};
  --BTN-HOVER: #5e1407;
  --BTN-BORDER: transparent;
  --BTN-BORDER-HOVER: #{$orange};
  --PREVIEW: #3d515c;
  --INPUT-COLOR: #575454;
  --fa-icon:#026164;
}
