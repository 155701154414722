$width: 25px;
@media (min-width: 600px) {
  $width: 35px;
}

.svg-inline--fa {
  font-size: $width;

  @media (min-width: 600px) {
    font-size: $width - 5px;
  }
  margin-right: 10px;
  color: var(--fa-icon);
}
