div.ui-toggle input[type="checkbox"] {
  display: none;
}

div.ui-toggle input[type="checkbox"]:checked + label {
  border-color: var(--UI-Toggle);
  background: var(--UI-Toggle);
  box-shadow: inset 0 0 0 10px var(--UI-Toggle);
}

div.ui-toggle input[type="checkbox"]:checked + label > div {
  margin-left: 20px;
}

div.ui-toggle label {
  transition: all 200ms ease;
  display: inline-block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--UI-Toggle-back);
  box-shadow: inset 0 0 0 0 var(--UI-Toggle);
  border: 2px solid var(--UI-Toggle-back);
  border-radius: 21px;
  width: 44px;
  height: 26px;
}

div.ui-toggle label div {
  transition: all 200ms ease;
  background: var(--UI-Toggle-label);
  width: 20px;
  height: 22px;
  border-radius: 10px;
}

div.ui-toggle label:hover,
div.ui-toggle label > div:hover {
  cursor: pointer;
}

div.ui-toggle.mb-10 {
  margin-bottom: 10px;
}
