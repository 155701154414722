.stepcontainer {
  text-align: center;
  margin: 15px 0;
  .step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: var(--UI-Toggle);
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 1;
  }

  .step.active {
    opacity: 1;
  }
  .step.finish {
    background-color: var(--UI-Toggle-back);
  }
}
