button,
input[type="button"],
input[type="submit"],
.knap {
  background-color: var(--BTN-COLOR);
  border-color: var(--BTN-BORDER);
  &:hover {
    background-color: var(--BTN-HOVER);
    border-color: var(--BTN-BORDER-HOVER);
  }

  color: var(--font-color);

  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid var(--BTN-BORDER);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow;
}
