@import "./overlay";

$width: 25px;
@media (min-width: 600px) {
  $width: 35px;
}

.inputwrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  :first-child {
    order: 1;
  }
  img {
    background-color: #ccc;
    width: 100%;
  }
  @media (min-width: 600px) {
    :first-child {
      order: 0;
    }
  }

  gap: 20px;
  justify-content: space-between;
}
#overlay .container {
  max-width: 100%;
  display: grid;
  margin-top: 100px;

  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  gap: 20px;
  justify-content: space-between;

  :first-child {
    order: 1;
    img {
      background-color: #ccc;
      width: 100%;
    }
  }

  @media (min-width: 600px) {
    max-width: 60%;
  }

  img {
    max-width: 100%;
  }
}

.stationary {
  background-color: var(--ODD);
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  h2 {
    font-weight: 900;
    padding-bottom: 10px;
  }

  &__location {
    margin: 20px 0;
    position: relative;
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .form-group {
      padding: 10px;

      // border: solid 1px red;

      div {
        width: 100%;
        display: flex;

        align-items: center;

        @media (max-width: 600px) {
          margin-left: 50%;
          transform: translateX(-25%);
        }

        .svg-inline--fa {
          font-size: $width;

          @media (min-width: 600px) {
            font-size: $width - 5px;
          }
          margin-right: 10px;
          color: var(--fa-icon);
        }
      }

      &:nth-child(even) {
        background-color: rgba(lightblue, 0.6);
      }
      &:nth-child(odd) {
        background-color: rgba(lightblue, 0.2);
      }
      input[type="radio"]:after {
        width: $width;
        height: $width;
        border-radius: $width;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        background-color: #d1d3d1;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }

      input[type="radio"]:checked:after {
        width: $width;
        height: $width;
        border-radius: $width;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        background-color: var(--UI-Toggle);
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }
    }
  }
}
.form-group__button {
  min-height: 100px;
  // border: solid 1px red;
  width: 100%;
  position: relative;

  .previous,
  .next,
  .submit {
    position: absolute;
  }

  .previous,
  .next {
    top: 10px;
  }

  .previous {
    left: 0;
  }
  .next {
    right: 0;
  }

  .submit {
    bottom: 0;
  }
}
.preview_image {
  height: auto;
  color: var(--PREVIEW);
}
