input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
textarea {
  border: 2px solid transparent !important;
  background-color: var(--INPUT-COLOR) !important;
  color: var(--TEXT-COLOR);
  

  &:focus {
    outline: none !important;
    border: 2px solid #719ece !important;
    //box-shadow: 0 0 10px #719ece !important;
  }
}
