html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Public Sans", sans-serif;
  background-color: var(--body-background-color);
  color: var(--TEXT-COLOR);
  overflow-y: scroll;
}
